.Presentation {
    text-align: left;
    margin-left: 15%;
}

.Presentation h1 {
    font-size: 50px;
}

.Presentation h4 {
    font-size: 30px;
}