.btn {
    background-color: #74fac0;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-weight: bolder;
    margin-top: 15px;
    cursor: pointer;
}

.btn:hover {
    color: aliceblue;
    background-color: #010b28;
    transform: translatey(-7px);
    transition-duration: 0.5s;

}