.Card {
    display: flex;
    justify-content: center;
    margin: 15px;
}

.Card img {

    width: 500px;
}

.Card img:hover {
    transform: translateY(-10px);
    cursor: pointer;
    transition-duration: 0.5s;
}

.Card section {
    background: rgba(1, 9, 28, 0.75);
    padding: 20px;
    border-radius: 20px;
    width: 500px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0.25, 0.25));
    margin-left: 25px;
}

.Card h3 {
    font-size: 25px;

}