.Skills {

    background: rgba(1, 9, 28, 0.75);
    border-radius: 10px;
    margin: 70px;
    padding: 25px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0.25, 0.25));
}

.Skills img {
    height: 140px;
    margin: 25px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0.25, 0.25));
    transition-duration: 0.5s;
}

.Skills img:hover {
    transform: translateY(-10px);
    transition-duration: 0.5s;
    height: 180px;
}


.Skills h1 {
    font-size: 45px;
}

.Skills p {
    font-size: 20px;
}