.navbar {
    display: flex;
    justify-content: space-around;
    padding: 1em;
}

.navbar ul {
    display: flex;
    list-style: none;
    align-items: center;

}

.navbar li {
    margin: 10px;

}

.navbar a {
    color: aliceblue !important;
}